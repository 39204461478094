<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group>
        <b-button
          :to="{ name: 'certificats-add' }"
          variant="gradient-primary"
          class="ml-1 d-inline-block"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Nouvelle Certificat</span>
        </b-button>
      </b-form-group>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="certificates"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'created_at'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.created_at.substring(0,10) }}</span>
        </div>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-warning"
              :to="{ name: 'certificats-edit',
                     params: { id: props.row.id} }"
            >    <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="16"
              @click="deleteJourFerier(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButton,
  BSpinner, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      user: {},
      columns: [
        {
          label: 'id',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Libellé',
          field: 'label',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher libellé',
          },
        },
        {
          label: 'Frais Inscription',
          field: 'registration_fees',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher Frais Inscription',
          },
        },
        {
          label: 'Frais Examen',
          field: 'exam_fees',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher Frais Examen',
          },
        },
        {
          label: 'code',
          field: 'code',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher Code',
          },
        },
        {
          label: 'Niveau',
          field: 'level',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher Niveau',
          },
        },
        {
          label: 'Active',
          field: 'is_active',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher Active',
          },
        },
        {
          label: 'Date Création',
          field: 'created_at',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher Date Création',
          },
        },

        // {
        //   label: 'UserCreate',
        //   field: 'user_create',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Chercher UserCreate',
        //   },
        // },
        // {
        //   label: 'Date Modif',
        //   field: 'update_at',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Chercher',
        //   },
        // },
        // {
        //   label: 'User Modif ',
        //   field: 'user_update',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Chercher',
        //   },
        // },
        // {
        //   label: 'Date création',
        //   field: 'created_at',
        //   formatFn: this.formatFn,
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Chercher date',
        //   },
        // },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      certificates: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      statuss: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    active() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },

  },
  created() {
    this.getCertificates()
    this.user = storeAuth.state.user
  },
  methods: {
    async getCertificates() {
      this.load = 'true'
      const response = await axios.get('/api/certificates/')
      this.certificates = response.data
      this.load = 'false'
    },
    deleteJourFerier(id) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axios
            .delete(`/api/certificates/delete/${id}/`)
            .then(() => {
              this.load = 'false'
              this.getCertificates()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'certificat supprimée avec succés')
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'certificat Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
